import { StepFormProps, StepsForm } from "@ant-design/pro-components";
import { Checkbox, Form, Select as SimpleSelect, Space } from "antd";
import { useEffect, useState } from "react";
import classes from "./StepFormItem.module.scss";
import { useFormDataStore } from "../../../../utils/store/formData";

import useDebounce from "../../../../hooks/useDebounce";

// type Props = {
//   type: "local" | "international";
//   statesData?: any;
// } & StepFormProps;

export default function StepFormItemOne({ type, statesData, ...props }) {
  const { formData, setFormData, isChecked, setIsChecked } = useFormDataStore();
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState({
    to: "a",
    from: "a",
  });
  const searchFromValue = useDebounce(searchValue.from, 500);
  const searchToValue = useDebounce(searchValue.to, 500);

  useEffect(() => {
    console.log("rendered");
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `https://truck-api-kappa.vercel.app/api/no-countries/${
        !searchFromValue ? "1" : searchFromValue
      }`,
      {}
    )
      .then((res) => res.json())
      .then((data) => {
        setData(
          data.map((i) => ({
            ...i,
            label: i.label,
            key: i.zip,
          }))
        );
      });
  }, [searchFromValue]);

  useEffect(() => {
    fetch(
      `https://truck-api-kappa.vercel.app/api/places/${
        !searchToValue ? "1" : searchToValue
      }`,
      {}
    )
      .then((res) => res.json())
      .then((data) => {
        setData(
          data.map((i) => ({
            ...i,
            label: i.label,
            key: i.zip,
          }))
        );
      });
  }, [searchToValue]);

  // if (!countriesData) {
  //   return <Loading />;
  // }

  return (
    <StepsForm.StepForm
      name="base"
      title="Information"
      stepProps={{

      }}
      {...props}
    >
      <Space wrap>
        <Form.Item
          label="Transport FROM"
          className={classes.form__item}
          name={"transport_from"}
          rules={[
            {
              message: "This field is required",
              required: true,
            },
          ]}
          initialValue={formData.information.transport_from}
        >
          <SimpleSelect
            allowClear
            placeholder="Zip or City"
            filterOption={(input, option) => {
              return (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            style={{
              minWidth: "180px",
            }}
            showSearch
            loading={isLoading}
            options={data}
            onFocus={() =>
              setSearchValue({
                ...searchValue,
                from: "a",
              })
            }
            onSearch={(value) =>
              setSearchValue({
                ...searchValue,
                from: value,
              })
            }
          />
        </Form.Item>
        {/* <div className={classes.line}></div> */}
        <Form.Item
          label="Transport TO"
          className={classes.form__item}
          name={"transport_to"}
          rules={[
            {
              message: "This field is required",
              required: true,
            },
          ]}
          initialValue={formData.information.transport_to}
        >
          <SimpleSelect
            allowClear
            onFocus={() =>
              setSearchValue({
                ...searchValue,
                from: "a",
              })
            }
            onSearch={(value) => {
              setSearchValue({
                ...searchValue,
                to: value,
              });
            }}
            filterOption={(input, option) => {
              return (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            style={{
              minWidth: "180px",
            }}
            showSearch
            onChange={(e) => console.log(e, "change")}
            placeholder="Zip, City or Country"
            options={data}
          />
        </Form.Item>
      </Space>
      <Space
        style={{
          maxWidth: "400px",
        }}
      >
        <Checkbox
          onChange={() => setIsChecked(!isChecked)}
          className={classes.privacy_police__checkbox}
        >
          <p className={classes.privacy_police__checkbox__text}>
            {/* By checking this box, you agree to our Terms and Privacy Policy,
            allowing us to send automated sales text messages to the provided
            phone number. Your consent is not required for purchasing any items,
            commodities, or services. Message and data rates may apply */}
            By checking this box, you agree to receive text messages from East Auto LLC.
          </p>
        </Checkbox>
      </Space>
    </StepsForm.StepForm>
  );
}
