import { create } from "zustand";

// interface IState {
//   formData: IFormData;
//   setFormData: Function;
// }

// export interface IFormData {
//   information: any;
// {
// transport_from: string;
// transport_to: string;
// };
// shipping: {
//   transportation_type?: string | null;
//   estimated_ship_date?: any;
// };
// vehicles: {
//   year: OBJ;
//   make: OBJ;
//   model: OBJ;
//   vehicle_runs: string | null;
// };
// contact: any;
// {
// phone: string;
// firstname: string;
// lastname: string;
// email: string;
// comment: string;
// };
// }

// interface OBJ {
//   value: string | null;
//   label: string | null;
// }

let dataObject = {
  label: null,
  value: null,
};

export const useFormDataStore = create()((set, get) => ({
  formData: {
    contact: { phone: "", email: "", firstname: "", lastname: "", comment: "" },
    information: {
      transport_from: "",
      transport_to: "",
    },
    shipping: {
      transportation_type: null,
      estimated_ship_date: null,
    },
    vehicles: {
      make: dataObject,
      model: dataObject,
      year: dataObject,
      vehicle_runs: null,
    },
  },
  isChecked: false,
  setIsChecked: (data) =>
    set({
      isChecked: data,
    }),
  setFormData: (data) =>
    set({
      formData: { ...get().formData, ...data },
    }),
}));
