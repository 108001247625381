import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarThree = React.lazy(() => import("../components/NavbarThree"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const ContactOne = React.lazy(() => import("../components/ContactOne"));
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarThree />

          {/* Breadcrumb */}
          <Breadcrumb title={"ABOUT US"} />

          {/* About Two */}
          <div className="pd-top-120 pd-bottom-120">
            <AboutTwo />
          </div>

          {/* Counter One */}

          <div className="fact-area" style={{ background: "#f9f9f9" }}>
            <CounterOne />
          </div>

          {/* Contact One */}
          <div
            className="call-to-contact-area pd-top-240"
            style={{ background: "#F9F9F9" }}
          >
            <ContactOne />
          </div>

          {/* Testimonial Two */}
          <TestimonialTwo />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;
