import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const BannerOne = React.lazy(() => import("../components/BannerOne"));
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const ContactOne = React.lazy(() => import("../components/ContactOne"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FeatureOne = React.lazy(() => import("../components/FeatureOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarThree = React.lazy(() => import("../components/NavbarThree"));
const ServiceOne = React.lazy(() => import("../components/ServiceOne"));
const FaqOne = React.lazy(() => import("../components/FaqOne"));
// const TestimonialOne = React.lazy(() => import("../components/TestimonialOne"));
const WhyChooseUsOne = React.lazy(() => import("../components/WhyChooseUsOne"));

const HomeOne = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarThree />

          {/* Banner One */}
          <BannerOne />

          {/* Feature One */}
          <FeatureOne />

          {/* About Two */}
          <AboutTwo />

          {/* Service One */}
          <ServiceOne />

          {/* Why Choose Us One */}
          <WhyChooseUsOne />

          {/* Counter One */}

          <CounterOne />

          {/* Contact One */}
          <div
            className="call-to-contact-area pd-top-240"
            style={{ background: "#F9F9F9" }}
          >
            <ContactOne />
          </div>

          {/* Faq */}
          <FaqOne />

          {/* Testimonial One */}
          {/* <TestimonialOne /> */}

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeOne;
