import {
  ProForm,
  ProFormDatePicker,
  ProFormList,
  ProFormSelect,
  ProFormText,
  StepFormProps,
  StepsForm,
  StepsFormProps,
} from "@ant-design/pro-components";
import { Space } from "antd";
import { useState } from "react";
import Select from "react-select";
import makeData from "../../../../data/automobile.json";
import ModelsData from "../../../../data/models.json";
import {
  primaryColor,
  primaryTransparentColor,
} from "../../../../styles/defaultStyles";

// type Props = {
//   type: "local" | "international";
//   vehicleClass: string;
// } & StepFormProps;

let arr = [];
for (let i = 1900; i <= new Date().getFullYear() + 1; i++) {
  arr.push({ label: i, value: i });
}

const SelectStyles = {
  input: (styles) => ({
    ...styles,
    padding: "0px",
    margin: "0px",
    outline: "red",
  }),
  container: (styles) => ({
    ...styles,
    minWidth: "216px",
    borderColor: "red",
  }),
  control: (styles, state) => {
    return {
      ...styles,
      outlineColor: "red",
      outline: "none",
      minHeight: "32px",
    };
  },
  indicatorsContainer: (styles) => ({
    ...styles,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
};

const SelectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: primaryColor,
      primary25: primaryTransparentColor,
    },
  };
};

export default function StepFormItemThree({ vehicleClass, ...props }) {
  const [selectedMake, setSelectedMake] = useState(null);

  const [models, setModels] = useState([]);

  async function FindModels(make) {
    const MakeModels = ModelsData.find((i) => i.make === make);

    if (MakeModels) {
      setModels(MakeModels?.models);
    }
  }

  return (
    <StepsForm.StepForm name="time" title="Vehicle" {...props}>
      <Space>
        <Space wrap className={vehicleClass}>
          <Space
            wrap
            style={{
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Space wrap>
              <ProForm.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Vehicle Runs"
                required
                name={"vehicle_runs"}
              >
                <Select
                  placeholder={"Select one"}
                  options={[
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ]}
                  name={"vehicle_runs"}
                  styles={{
                    ...SelectStyles,
                    container: (styles) => ({
                      ...styles,
                      minWidth: "210px",
                    }),
                  }}
                  theme={SelectTheme}
                ></Select>
              </ProForm.Item>
              <ProForm.Item
                label="Year"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
                name={"year"}
              >
                <Select
                  name="Year"
                  required
                  className="select-s"
                  options={arr.sort((a, b) => {
                    return a.value < b.value ? 1 : -1;
                  })}
                  styles={SelectStyles}
                  theme={SelectTheme}
                />
              </ProForm.Item>
            </Space>
            <Space wrap>
              <ProForm.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name={"make"}
                label="Make"
              >
                <Select
                  placeholder={"Make"}
                  required
                  name={"make"}
                  styles={SelectStyles}
                  onChange={async (e) => await FindModels(e?.label)}
                  options={makeData.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  theme={SelectTheme}
                />
              </ProForm.Item>
              <ProForm.Item
                name={"model"}
                label={"Model"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={"Model"}
                  name={"model"}
                  required
                  options={models?.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  styles={SelectStyles}
                  theme={SelectTheme}
                />
              </ProForm.Item>
            </Space>
          </Space>
        </Space>
      </Space>
      {/* <ProFormList
        required
        name="datas"
        creatorButtonProps={{
          creatorButtonText: "Add vehicle",
        }}
        alwaysShowItemLabel={true}
      >
        {() => {
          return (
            <div>
              <Space wrap className={vehicleClass}>
                <Space wrap>
                  <ProFormSelect
                    placeholder={"Select one"}
                    label="Vehicle Runs"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    options={[
                      {
                        label: "Yes",
                        value: "yes",
                      },
                      {
                        label: "No",
                        value: "no",
                      },
                    ]}
                    name={"vehicle_runs"}
                  ></ProFormSelect>
                  <ProFormSelect
                    name={"year"}
                    label={"Year"}
                    placeholder={"Year"}
                    options={[
                      {
                        label: "2022",
                        value: "2022",
                      },
                      {
                        label: "2021",
                        value: "2021",
                      },
                    ]}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  ></ProFormSelect>
                  <ProFormText
                    placeholder={"Make"}
                    label={"Make"}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    name={"make"}
                  />
                  <ProFormText
                    placeholder={"Model"}
                    name={"model"}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label={"Model"}
                  />
                </Space>
                <Space wrap>
                  <ProFormText
                    placeholder={"Length (in)"}
                    label={"Length (in)"}
                    required
                    name={"vehicle_length"}
                  />
                  <ProFormText
                    placeholder={"Width (in)"}
                    label={"Width (in)"}
                    name={"vehicle_width"}
                    required
                  />
                  <ProFormText
                    placeholder={"Height (in)"}
                    label={"Height (in)"}
                    name={"vehicle_height"}
                    required
                  />
                </Space>
              </Space>
            </div>
          );
        }}
      </ProFormList> */}
    </StepsForm.StepForm>
  );
}
