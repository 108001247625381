import {
  ProForm,
  ProFormDatePicker,
  ProFormSelect,
  ProFormText,
  StepFormProps,
  StepsForm,
  StepsFormProps,
} from "@ant-design/pro-components";
import { Space } from "antd";

// type Props = {
//   type: "local" | "international";
// } & StepFormProps;

export default function StepFormItemTwo({ ...props }) {
  return (
    <StepsForm.StepForm name="checkbox" title="Transportation" {...props}>
      <Space wrap>
        <ProFormSelect
          label="Transportation Type*"
          name="transportation_type"
          width={"md"}
          allowClear={false}
          rules={[
            {
              required: true,
            },
          ]}
          placeholder={"Select"}
          options={[
            {
              value: "open",
              label: "Open",
            },
            { value: "enclosed", label: "Enclosed" },
            { value: "driveaway", label: "Driveaway" },
          ]}
        />
        <ProFormDatePicker
          label="Estimated Ship Date"
          name={"estimated_ship_date"}
          placeholder={""}
          fieldProps={{
            disabledDate: (e) => e.isBefore(),
            format: "MM/DD/YYYY",
            onChange: (e) => console.log(e, "DAAAAA"),
          }}
          width={"sm"}
          rules={[
            {
              required: true,
            },
          ]}
        />
      </Space>
    </StepsForm.StepForm>
  );
}
