import { Space, Button } from "antd";
import { useFormDataStore } from "../../../utils/store/formData";

// type Props = {
//   current: number;
//   set: Function;
//   formElement: any;
// };

export default function StepButtons({ current, formElement, set }) {
  const {isChecked} = useFormDataStore()
  let Buttons = {
    0: (
      <Button
        type="primary"
        className="px-5"
        onClick={() => formElement.form?.submit()}
        disabled={!isChecked}
      >
        Next
      </Button>
    ),
    1: (
      <Button type="primary" onClick={() => formElement.form?.submit()}>
        Next
      </Button>
    ),
    2: (
      <Button type="primary" onClick={() => formElement.form?.submit()}>
        Next
      </Button>
    ),
    3: (
      <Button type="primary" onClick={() => formElement.form?.submit()} >
        Submit
      </Button>
    ),
  };

  return (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: "12px",
      }}
    >
      {current !== 0 && (
        <Button onClick={() => set(--current)}>Previous</Button>
      )}
      {/* {current === 3 ? (
        <Button
          htmlType="submit"
          type="primary"
          onClick={() => formElement.form?.submit()}
        >
          Submit
        </Button>
      ) : current === 0 ? (
        <Button
          type="primary"
          onClick={() => {
            formElement.form?.submit();
          }}
        >
          Next
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => {
            formElement.form?.submit();
          }}
        >
          Next
        </Button>
      )} */}
      {Buttons[current]}
    </Space>
  );
}
