import { LocalForm } from "../components/StepsForm";
import { primaryColor } from "../styles/defaultStyles";

function Quote() {
  return (
    <div
      style={{
        backgroundColor: primaryColor,
      }}
      className="d-flex align-items-center min-vh-100 "
    >
      <div
        className="p-3 "
        style={{
          width: "100vw",
          height: "100%",
          background: "red",
        }}
      >
        <LocalForm />
      </div>
    </div>
  );
}

export default Quote;
