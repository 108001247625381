import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeOne from "./pages/HomeOne";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/About";
import Contact from "./pages/Contact";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import Quote from "./pages/FormTest";

import loc from "antd/locale/en_US";
import { ConfigProvider } from "antd";

import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivcanyPolicy/index";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ConfigProvider
        locale={loc}
        theme={{
          token: {
            colorPrimary: "#fa4318",
          },
          components: {},
        }}
      >
        <Routes>
          <Route exact path="/" element={<HomeOne />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/quote" element={<Quote />} />
          <Route
            
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ConfigProvider>

      <ScrollToTop smooth color="#FA4318" />
    </BrowserRouter>
  );
}

export default App;
